import React, { useState } from 'react';
import { Box, Typography, Modal, IconButton, Fab, Tooltip, Stack, Chip, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import TwitterIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import ArrowDownIcon from '@mui/icons-material/ArrowCircleDown';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';


import "./App.scss";
import "animate.css/animate.min.css";
import CmcLogo from './components/svg/CmcLogo';
import DextoolLogo from './components/svg/DextoolLogo';
import CopyToClipboard from './components/utils/CopyToClipboard';
import SolanaLogo from './components/svg/SolanaLogo';
import DexscreenerLogo from './components/svg/DexscreenerLogo';
import FullScreenView from './components/FullScreenView';
import AnimatedLogo from './components/AnimatedLogo';

const theme = createTheme({
    typography: {
        fontFamily: 'Londrina, Arial',
    },
    palette: {
      mode: 'dark',
      primary: {
        main: '#06fece',
      },
      secondary: {
        main: '#f0a',
      },
      background: {
        default: '#1d242c',
        paper: '#1d242c',
      },
      text: {
        secondary: '#06fece',
      },
    },
    shape: {
      borderRadius: 0,
    },
  });

const App: React.FC = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    p:0,
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: '#01e9ff',
                    position: 'relative',
                }}
            >
                <Stack direction="row" sx={{ position: 'absolute', top: 16, right: 16, zIndex: 9 }}>
                    <Typography color="secondary" variant='h5' sx={{pt:0.5}}>
                        Menu
                    </Typography>
                    <IconButton
                        onClick={handleOpen}
                        color='secondary'
                    >
                        <MenuIcon />
                    </IconButton>
                </Stack>
                <Container maxWidth="xl">
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: 2,
                            mt: 5
                        }}
                    >
                        <Typography mr={{ xs: 0, sm: -10 }} mb={{ xs: -6, sm: 0}} variant="h1" sx={{ color: "white", zIndex: 5 }}
                            className="text-meme text-meme-main">
                            DEEZ
                        </Typography>
                        <AnimatedLogo/>
                        <Typography ml={{ xs: 0, sm: -10 }} mt={{ xs: -6, sm: 0}} variant="h1" sx={{ color: "white", zIndex: 5 }}
                            className="text-meme-alt">
                            NUTS
                        </Typography>
                    </Stack>
                </Container>

                <Stack direction={"row"}
                    className="animate__animated animate__rubberBand"
                    spacing={{ xs: 0.5, sm: 2, md: 2 }}
                    justifyContent={{ xs: "center", md: "left" }}
                    useFlexGap flexWrap="wrap"
                    sx={{ mb: 2 }}>
                    <Tooltip title="Telegram">
                        <Fab className="socialIcon"
                            href="https://t.me/NutsOnBase"
                            rel="noopener"
                            target="blank"
                            color="primary"
                            sx={{marginRight: 0.8, backgroundColor:"#0088cc", color:"#fff", border: "2px solid #000"}}
                            aria-label="telegram"
                            size="small">
                            <TelegramIcon sx={{ width: 50, height: 50 }} />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Twitter/X">
                        <Fab className="socialIcon"
                            href="https://x.com/NutsOnBase"
                            rel="noopener"
                            target="blank"
                            color="primary"
                            sx={{marginRight: 0.8, backgroundColor:"#000", color:"#fff", border: "2px solid #000"}}
                            aria-label="Twitter/X"
                            size="small">
                            <TwitterIcon sx={{ width: 40, height: 40 }} />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="CoinMarketCap">
                        <Fab className="socialIcon"
                            href="https://coinmarketcap.com/currencies/deeznuts/"
                            rel="noopener"
                            target="blank"
                            color="primary"
                            sx={{marginRight: 0.8, backgroundColor:"#3861FB", border: "2px solid #000"}}
                            aria-label="edit"
                            size="large">
                            <CmcLogo></CmcLogo>
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Jupiter">
                        <Fab className="socialIcon"
                            href="https://x.com/NutsOnBase"
                            rel="noopener"
                            target="blank"
                            color="secondary"
                            sx={{marginRight: 0.8, color:"#fff", border: "2px solid #000"}}
                            aria-label="Jupiter"
                            size="small">
                            <SolanaLogo/>
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Dextools">
                        <Fab className="socialIcon"
                            rel="noopener"
                            target="blank"
                            href="https://www.dextools.io/app/en/ether/pair-explorer/0x9faf7ad8711f6b87cfcf39c235b0a03ef4bdd7c4"
                            color="primary"
                            sx={{marginRight: 0.8, backgroundColor:"#00b8d8", color:"#fff", border: "2px solid #000"}}
                            aria-label="Dextools"
                            size="small">
                        <DextoolLogo/>
                        </Fab>
                    </Tooltip>
                    <Tooltip title="DexScreener">
                        <Fab className="socialIcon"
                            rel="noopener"
                            target="blank"
                            href="https://www.dextools.io/app/en/ether/pair-explorer/0x9faf7ad8711f6b87cfcf39c235b0a03ef4bdd7c4"
                            color="primary"
                            sx={{marginRight: 0.8, backgroundColor:"#000", color:"#fff", border: "2px solid #000"}}
                            aria-label="DexScreener"
                            size="small">
                            <DexscreenerLogo/>
                        </Fab>
                    </Tooltip>
                </Stack>
                <Container maxWidth="xl" sx={{textAlign: "center", pt:2}}>
                    <Chip
                        sx={{
                            fontSize: "1.3rem",
                        color: "secondary",
                        pl: 1,
                        }}
                        // icon={<SolanaLogo    />}
                        label="CA: 2c95d751da37a5c1d9c5a7fd465c1d50f3d96160"
                        component="a"
                        target="blank"
                        color="secondary"
                        rel="noopener"
                        href="https://etherscan.io/address/0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160"
                        clickable
                        />
                        <CopyToClipboard/>
                </Container>
                <Container maxWidth="xl" sx={{textAlign: "center", pt:2}}>
                    <Chip
                        sx={{
                            fontSize: "1.3rem",
                        color: "secondary",
                        border:"2px solid #000",
                        pl: 1,
                        }}
                        icon={<ArrowDownIcon/>}
                        label="SHOW ME MORE..."
                        component="button"
                        color="primary"
                        size='medium'
                        onClick={handleOpen}
                        clickable
                        />
                </Container>
                <Modal open={open} onClose={handleClose} className="animate__animated  animate__backInDown animate__faster">
                    <Box
                        sx={{
                            height: '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: '#f0a',
                        }}
                    >
                        <Fab onClick={handleClose} sx={{mb: 5, zIndex:2}}>
                            <CloseIcon />
                        </Fab>
                        <Typography variant="h2" sx={{ mb: 2, color: "white", fontSize: "5rem" }} className='text-meme-menu'>
                            Menu
                        </Typography>
                        <FullScreenView/>
                    </Box>
                </Modal>
            </Box>
        </ThemeProvider>
    );
};

export default App;