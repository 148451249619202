import * as React from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';

interface State extends SnackbarOrigin {
  open: boolean;
}

export default function PositionedSnackbar() {

    const [state, setState] = React.useState<State>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const handleClick = (newState: SnackbarOrigin) => () => {
      setState({ ...newState, open: true });
      navigator.clipboard.writeText("0x2c95d751da37a5c1d9c5a7fd465c1d50f3d96160")
    };

    const handleClose = () => {
      setState({ ...state, open: false });
    };

    const buttons = (
      <React.Fragment>
         <IconButton color="secondary" onClick={handleClick({ vertical: 'top', horizontal: 'center' })}>
            <ContentCopyIcon fontSize="small"/>
          </IconButton>
      </React.Fragment>
    );

    return (
      <>
           {buttons}
          <Snackbar
            open={open}
            key={vertical + horizontal}
            anchorOrigin={{ vertical, horizontal }}
            onClose={handleClose}
            autoHideDuration={2000}
            message="Contract Address copied to clipboard"
          />
        </>
    );
}
