import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';

const AnimatedLogo: React.FC = () => {
  const [frameIndex, setFrameIndex] = useState(1);
  const totalFrames = 16;
  const frameInterval = 100; // milliseconds

  useEffect(() => {
    const interval = setInterval(() => {
      setFrameIndex((prevIndex) => (prevIndex % totalFrames) + 1);
    }, frameInterval);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box className="animate__animated animate__rubberBand" id='animatedLogo'>
        <Box className="animate__animated animate__bounceInDown">
          <img
              src={`/frames/${frameIndex}.svg`}
              alt={`Frame ${frameIndex}`}
          />
        </Box>
    </Box>
  );
};

export default AnimatedLogo;